/*
 * @version: 1.0
 * @Date: 2023-08-15 09:20:26
 * @LastEditors: 杨慢慢
 */
import TextInput from './components/TextInput.vue'
import TextareaInput from './components/TextareaInput.vue'
import NumberInput from './components/NumberInput.vue'
import AmountInput from './components/AmountInput.vue'
import SelectInput from './components/SelectInput.vue'
import MultipleSelect from './components/MultipleSelect.vue'
import DateTime from './components/DateTime.vue'
import DateTimeRange from './components/DateTimeRange.vue'
import FileUpload from './components/FileUpload.vue'
import Address from './components/Address.vue'
import SealInfo from './components/SealInfo.vue'
import TakeOut from './components/TakeOut.vue'
import Phone from './components/Phone.vue'
import ItemID from './components/ItemID.vue'

export default {
  //基础组件
  TextInput,
  TextareaInput,
  NumberInput,
  AmountInput,
  SelectInput,
  MultipleSelect,
  DateTime,
  DateTimeRange,
  FileUpload,
  Address,
  SealInfo,
  TakeOut,
  Phone,
  ItemID
}
